import { gettingStarted, stepsToFindJobs } from '@/data'
import React, { useRef, useState } from 'react'
import { FiArrowRight } from 'react-icons/fi'
import { FiSearch } from 'react-icons/fi'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import clsx from 'clsx'

import {
  filterJob,
  glassAnimation,
  magnifierAnimation,
  RECRUITER_SIGN_UP,
} from '@/utils'
import {
  GoogleAds,
  Infinite,
  Jobs,
  PageSpinner,
  ToggleSwitch,
} from '@/components'
import { useFilterBySearch, useIntersectionObserver } from '@/hooks'
import { useJobs } from '@/features/job-seeker'
import Head from 'next/head'

const Index = () => {
  const { searchTerm, handleSearchChange } = useFilterBySearch()
  const ourServiceRef = React.useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ourServiceRef, {
    threshold: 0.1,
  })
  const [hasUpdate, setHasUpdate] = React.useState(false)
  const isVisible = !!entry?.isIntersecting
  const ref2 = useRef<HTMLDivElement>(null)
  const { data: jobData, isLoading: isLoadingJobs } = useJobs()
  const jobs = jobData?.data?.filter((job) => job.status === 'published') || []
  const [isToggled, setIsToggled] = useState(false)
  const [stepIndex, setStepIndex] = useState(1)

  React.useEffect(() => {
    if (isVisible && !hasUpdate) {
      setIsToggled(!isToggled)
      setHasUpdate(true)
    }
  }, [isVisible])

  const onToggle = () => {
    setIsToggled(!isToggled)
  }

  const filteredJobs = jobs.filter((job) => filterJob(job, searchTerm))

  const fewJobs = filteredJobs.slice(0, 20)

  return (
    <>
      <Head>
        <title>
          Scout4Job | Jobs and Recruitment on scout4job.com, the #1 job site
        </title>
        <meta
          name="description"
          content="Scout4job is a recruitment platform with the sole purpose of bridging the gap between recruiters and job seekers."
        />
        <meta
          name="keywords"
          content="job openings, employment opportunities, career listings, job search, find jobs, job vacancies, hiring now, work opportunities, job applications, job board, job listings, job portal, jobs near me, job search engine, online job search, job posting site, job classifieds, job alerts, job seekers, job match, free job posting sites in nigeria, recruitment portals in nigeria, remote jobs, full-time jobs, interviews, job seeker tips, interview tips, job fair, resume builder, internship, entry-level jobs"
        />
        <meta
          property="og:title"
          content="Scout4job | Jobs and Recruitment on scout4job.com, the #1 job site"
        />
        <meta
          property="og:description"
          content="Scout4job is a recruitment platform with the sole purpose of bridging the gap between recruiters and job seekers."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/scout4job/image/upload/v1696514292/uc_g0zqgl.png"
        />
        <meta property="og:url" content="https://scout4job.com" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Scout4job | Jobs and Recruitment on scout4job.com, the #1 job site"
        />
        <meta name="twitter:site" content="@scout4job" />
        <meta
          name="twitter:description"
          content="Scout4job is a recruitment platform with the sole purpose of bridging the gap between recruiters and job seekers."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/scout4job/image/upload/v1696514292/uc_g0zqgl.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <div className="relative overflow-x-hidden px-4 text-center sm:px-6 md:px-16 md:text-left">
        <section className="w-full pb-[3rem] text-center lg:pb-[7rem] lg:text-left">
          <div className="lg:gap:0  mx-auto box-border flex max-w-[1500px] flex-col items-center justify-between pt-12 lg:mt-[2rem] lg:flex-row lg:items-start">
            <div>
              <h1 className="text-darkGreen mt-20  text-[40px] font-[800] leading-[1.23] sm:mt-16 md:mt-0 md:text-[72px]">
                The Quickest <br /> way to get{' '}
                <span className="hire">Hired!</span>
              </h1>
              <p className="text-darkGreen  mt-9 text-base leading-8 md:text-[18px]">
                Join our 200k affiliates for{' '}
                <span className="font-extrabold italic">
                  Great Opportunities.
                </span>{' '}
                Receive your <br /> top new matches directly in your inbox
              </p>
              <div className="mb-10 mt-[4rem] hidden lg:mb-0 lg:block">
                <Infinite />
              </div>
              <Link
                href="/sign-up"
                className="mt-10 hidden w-fit items-center gap-5 bg-secondary py-[12.5px] px-[38px] text-xs font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919] md:text-sm lg:mt-[4rem] lg:flex"
              >
                <p className="text-xs md:text-sm">Find a Job</p>
                <FiArrowRight />
              </Link>
              <p className="mt-[3.5rem] hidden lg:block">
                Are you looking to hire instead?{' '}
                <Link
                  href={RECRUITER_SIGN_UP}
                  className="font-bold text-primary"
                >
                  Hire Candidates!
                </Link>{' '}
              </p>
            </div>
            <div className="relative h-[400px] w-full md:w-[630.5px] ">
              <motion.div
                className="magnifier absolute  z-[40] ml-[50px] -mt-[8.9px] h-[300px] w-[300px]  min-w-[300px]  rounded-full border-4 border-white"
                variants={magnifierAnimation}
                initial="initial"
                animate="show"
              >
                <Image
                  className="absolute left-[217px] top-[263px] hidden lg:block"
                  src="/assets/handle.png"
                  width={247}
                  height={248}
                  alt="magnifier-handle"
                  loading="lazy"
                />
              </motion.div>

              <motion.div
                className="absolute z-[2] mt-10  h-fit w-fit p-0"
                variants={glassAnimation}
                initial="initial"
                animate="show"
                ref={ref2}
              >
                <Image
                  src="/assets/blurImage2.png"
                  alt="screen"
                  height={630.5}
                  width={899}
                  priority
                />
              </motion.div>

              <motion.div className="blurcard absolute z-[1] mt-10 h-fit w-fit p-0">
                <Image
                  src="/assets/blurImage2.png"
                  alt="screen"
                  height={630.5}
                  width={899}
                  priority
                />
              </motion.div>
            </div>
            <div className="mt-[-4rem] w-full sm:mt-[6rem] md:mt-[2rem] lg:hidden">
              <Infinite />
            </div>
            <Link
              href="/jobs"
              className="mt-10 flex w-fit items-center gap-5 bg-secondary py-[12.5px] px-[38px] text-sm font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919] lg:hidden"
            >
              <p className="">Find a Job</p>
              <FiArrowRight />
            </Link>
            <p className="mt-10 block lg:hidden">
              Looking to hire instead?{' '}
              <span className="font-bold text-primary underline">
                {' '}
                Hire Candidates!
              </span>{' '}
            </p>
          </div>
        </section>

        <section className="w-full  pb-[7rem]">
          <div className=" mx-auto grid w-full max-w-[1500px]  items-center md:grid-cols-12">
            <div className=" mt-0 box-border md:col-span-5 md:mt-0">
              <h2 className="mb-[1rem] text-[30px] font-semibold leading-[1.1] md:text-[2.5rem]">
                We are a leading worldwide online recruitment marketplace
              </h2>
              <p className="text-lg">
                Our vision is to help businesses get the right fit for the roles
                they want to fill and help individuals worldwide connect to the
                right job that fits their life goals. We bring benefits to
                competent job seekers, start-ups, and establishments.
              </p>
              <div className="mt-[1rem] flex w-full items-center justify-center gap-[20px] lg:justify-start">
                <Link
                  href={RECRUITER_SIGN_UP}
                  className="display mt-[3rem] flex w-fit items-center gap-5 bg-secondary py-[12.5px] px-[38px] text-xs font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919] md:text-sm "
                >
                  Sign up now
                </Link>
              </div>
            </div>
            <div className="col-span-1"></div>
            <div className="mt-[4rem] flex w-full items-center justify-center md:col-span-6">
              <Image
                src="/assets/screen1.png"
                alt="screen"
                height={633}
                width={755}
              />
            </div>
          </div>
        </section>

        <section ref={ourServiceRef} className="w-full pb-[3rem] lg:pb-[7rem] ">
          <div className="mx-auto mt-0 flex w-full max-w-[1500px] flex-col items-center justify-between lg:mt-10">
            <div className="flex items-center justify-center gap-8">
              <span
                className={clsx(
                  '',
                  !isToggled ? 'font-bold text-primary' : 'text-black'
                )}
              >
                I am a recruiter{' '}
              </span>
              <ToggleSwitch isToggled={isToggled} onToggle={onToggle} />
              <span
                className={clsx(
                  '',
                  isToggled ? 'font-bold text-primary' : 'text-black'
                )}
              >
                I am looking for a job
              </span>
            </div>
            {isToggled && (
              <>
                <div className="flex flex-col items-center">
                  <h3 className="mt-4 text-center text-[30px] md:text-[2.5rem]">
                    Find Jobs with 3 easy <br className="hidden sm:block" />{' '}
                    steps
                  </h3>
                  <p className="mt-5 text-center text-base md:text-lg">
                    Give yourself the power of responsibility. Remind yourself{' '}
                    <br /> the only thing stopping you is yourself.
                  </p>
                </div>
                <div className="mt-10 grid grid-cols-1 items-center  justify-center gap-6 md:grid-cols-2 lg:gap-[3rem] xl:flex xl:flex-row 2xl:gap-[9rem]">
                  {stepsToFindJobs.map((data, index) => (
                    <div
                      className=" mt-4 flex flex-col items-center gap-6"
                      key={index}
                    >
                      <Image
                        src={data.images}
                        alt={data.images}
                        width={280}
                        height={240}
                        style={{ width: 'auto', height: 'auto' }}
                      />

                      <span className="h-[38px] w-[38px] rounded-full bg-black pt-2 text-center text-white shadow-[3px_3px_0px_0px_#16BF44] ">
                        {data.number}
                      </span>
                      <h3 className="font-bold md:text-2xl">{data.step}</h3>
                      <p className="mt-[-10px] text-center text-base lg:w-[407px]">
                        {data.description}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
            {!isToggled && (
              <div>
                <div className="flex w-full  justify-center lg:gap-[60rem] xl:gap-[40rem]">
                  <div className="flex  flex-col ">
                    <h3 className=" mt-8 text-center text-[25px] sm:text-[35px] md:text-[40px] ">
                      Getting started is easy
                    </h3>
                    <p className="whites[ace-nowrap]  mt-8 text-center text-sm md:text-lg ">
                      Create a profile and we’ll match you with the best <br />{' '}
                      candidates that meet your company&apos;s standards
                    </p>
                  </div>
                </div>
                {gettingStarted.map((data, index) => {
                  if (data.num == stepIndex) {
                    return (
                      <div
                        key={index}
                        className="m-auto mt-8 flex flex-col items-center justify-between gap-20 rounded-3xl border border-gray-400 p-[1rem] sm:max-w-[80%] md:flex-row md:p-[3rem]"
                      >
                        <div
                          className="hidden w-full flex-1 rounded-2xl bg-black bg-cover bg-center bg-no-repeat md:block md:h-[311px]"
                          style={{ backgroundImage: `url(${data.img})` }}
                        ></div>
                        <div className="flex-1">
                          <div>
                            <p className="text-sm md:text-lg ">
                              {data.number}.
                            </p>
                            <h4 className="mt-3 text-lg md:text-[32px]">
                              {data.navTitle}
                            </h4>
                            <p className="mt-4 text-lg">{data.vieDesc}</p>
                            <div className="mt-6 border-t-[0.5px] border-gray-300">
                              {data.points.map((point, Index) => {
                                return (
                                  <div
                                    className="flex-start mt-4 flex gap-3"
                                    key={Index}
                                  >
                                    <div className="relative w-[20px] md:w-[40px]">
                                      <Image
                                        height={20}
                                        width={20}
                                        src="/assets/Checkbox.png"
                                        alt="Checkbox"
                                        key={Index}
                                      />
                                    </div>
                                    <p className="flex-1 text-left text-xs md:text-sm">
                                      {point}
                                    </p>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
                <div className="mx-auto mt-16 mb-20 flex max-w-[1500px] items-center justify-center gap-8 sm:mt-20 sm:items-start sm:gap-20 ">
                  {gettingStarted.map((data, Index) => {
                    return (
                      <div
                        key={Index}
                        onClick={() => setStepIndex(data.num)}
                        className={
                          Index + 1 == stepIndex
                            ? ' sm:flex-1  sm:border-t-4 sm:border-t-primary sm:pb-[2rem] sm:pt-[3rem]'
                            : ' transition  duration-200 ease-in-out sm:flex-1 sm:border-t-4 sm:border-t-gray-400 sm:pb-[2rem] sm:pt-[3rem] sm:hover:border-t-4 sm:hover:border-t-[black]'
                        }
                      >
                        <p
                          className={
                            Index + 1 == stepIndex
                              ? 'flex h-[38px] w-[38px] items-center justify-center rounded-full bg-white text-center text-black shadow-[3px_3px_0px_0px_#16BF44] transition duration-200 ease-in-out sm:bg-black sm:pt-1 sm:pb-2 sm:text-white'
                              : 'flex h-[38px] w-[38px] items-center justify-center rounded-full bg-black text-center text-white shadow-[3px_3px_0px_0px_#16BF44] transition duration-200 ease-in-out hover:bg-white hover:text-black sm:pt-1 sm:pb-2'
                          }
                        >
                          {data.num}
                        </p>
                        <h4 className="mt-6 hidden text-base sm:block md:text-xl">
                          {data.navTitle}
                        </h4>
                        <p className="mt-2 hidden text-sm sm:block md:text-base">
                          {data.navDesc}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="mt-8 text-center md:mt-16">
            <Link
              href={isToggled ? '/sign-up' : RECRUITER_SIGN_UP}
              className="w-fit bg-secondary py-[12.5px] px-[38px] text-sm font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44]  transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919]"
            >
              Sign up now
            </Link>
          </div>
        </section>
          <GoogleAds />
        {Jobs.length > 0 ? (
          <>
            <section className="w-full pb-[7rem] pt-9 lg:pt-0 ">
              <div className="">
                <div className="mx-auto mb-[3rem] w-full max-w-[1500px] rounded-2xl bg-black py-12 text-center text-[#EFEFEF]">
                  <div className="mx-auto px-4 md:w-7/12 md:px-0">
                    <h3 className="mb-[1rem] py-4 text-[20px] font-semibold leading-[1.1] md:text-[2.5rem]">
                      Let&apos;s help you find a job based on your experience
                      and expertise
                    </h3>
                    <p>
                      We help career professionals get hired with better
                      remuneration, compensation and career growth.
                    </p>

                    <div className="my-8 flex items-center gap-2">
                      <input
                        type="text"
                        className="h-[3.2rem] w-[100%] bg-[#2B2B2B] px-5 font-WorkSans outline-none "
                        placeholder="What kind of job are you looking for?"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                      <div className="flex h-[3.2rem] w-[3.2rem] items-center justify-center bg-black text-center text-2xl text-white shadow-[3px_3px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-3px_3px_0px_0px_#191919]">
                        <FiSearch />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {isLoadingJobs ? (
                    <PageSpinner className="mx-auto" />
                  ) : (
                    <>
                      <div className="mx-auto mt-5 max-w-[1500px] bg-[#FCFCFC]">
                        <Jobs jobs={jobs} currJobs={fewJobs} />
                      </div>
                      <div className="text-angelGreen hover:text-liteGreen mt-10 flex w-full items-center justify-center gap-4 transition duration-200 ">
                        <Link
                          href="/jobs"
                          className=" w-fit self-center bg-secondary py-[12.5px]  px-[38px] text-xs font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919] md:text-sm"
                        >
                          See All Jobs
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          </>
        ) : (
          <></>
        )}

        <style>
          {`
        .magnifier {
          box-shadow:   10px 10px 20px #b8b8b8,
          -10px -10px 20px #ffffff;

        }
        }
        `}
        </style>
      </div>
    </>
  )
}

export default Index
